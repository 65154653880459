import { Route } from '@angular/router';
import { InitialDataResolver } from './app.resolvers';
import { MainComponent } from './pages/main/main.component';
import { AuthGuestGuard } from './shared/utils/auth-guest.guard';
import { AuthGuard } from './shared/utils/auth.guard';
import {  RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
export const routes: Route[] = [

  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
 }, {
    path: '',
    component: MainComponent,
    resolve: {
       initialData: InitialDataResolver,
    },


    children: [

      { path: 'home', loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule) },
      { path: '', loadChildren: () => import('./pages/Products/Products.module').then(m => m.ProductsModule), data: { breadcrumb: 'All Products' } },
      { path: '', loadChildren: () => import('./pages/product/product.module').then(m => m.ProductModule), data: { breadcrumb: 'Product' } },
     { path: '', loadChildren: () => import('./pages/category/category.module').then(m => m.CategoryModule), data: { breadcrumb: 'Category' } },
      { path: '', loadChildren: () => import('./pages/general/general.module').then(m => m.GeneralModule) },
      { path: 'coupon', loadChildren: () => import('./pages/coupon/coupon.module').then(m => m.CouponModule), data: { breadcrumb: 'Coupon' } },
      { path: '', loadChildren: () => import('./pages/categories/categories.module').then(m => m.CategoriesModule) },
      { path: 'contact-us', loadChildren: () => import('./pages/contact/contact.module').then(m => m.ContactModule), data: { breadcrumb: 'CONTACT-US' } },
      { path: 'blog', loadChildren: () => import('./pages/blog/blog.module').then(m => m.BlogModule), data: { breadcrumb: 'BLOG' } },
      { path: 'catalogue', loadChildren: () => import('./pages/catalogue/catalogue.module').then(m => m.CatalogueModule) },
      { path: 'brochure', loadChildren: () => import('./pages/brochure/brochure.module').then(m => m.BrochureModule) , data: { breadcrumb: 'Brochure' } }, 
      { path: 'booking-detail', loadChildren: () => import('./pages/doctor-detail/doctor-detail.module').then(m => m.DoctorDetailModule) },
      { path: 'checkout-appointments', loadChildren: () => import('./pages/checkout-payout/checkout-payout.module').then(m => m.CheckoutPayoutModule), data: { breadcrumb: 'Checkout' } },
      { path: 'service-plan', loadChildren: () => import('./service-plan/service-plan.module').then(m => m.ServicePlanModule) },
      { path: 'product-list-temp', loadChildren: () => import('./pages/product-list-temp/product-list-temp.module').then(m => m.ProductListTempModule) },
      { path: '', loadChildren: () => import('./pages/restaurants/restaurants.module').then(m => m.RestaurantsModule) },
   ]
 },
 {
  path: '',
  component: MainComponent,
  canActivate: [AuthGuard],
  resolve: {
     initialData: InitialDataResolver,
  },
  children: [
    { path: 'orders', loadChildren: () => import('./pages/orders/orders.module').then(m => m.OrdersModule), data: { breadcrumb: 'My Orders' } },
    { path: 'myappoint', loadChildren: () => import('./pages/myappoint/myappoint.module').then(m => m.MyappointModule) , data: { breadcrumb: 'My AppointMents' } },
    { path: '', loadChildren: () => import('./pages/review-rating/review-rating.module').then(m => m.ReviewRatingModule) },
    { path: 'account', loadChildren: () => import('./pages/account/account.module').then(m => m.AccountModule) },
  ]
},

{
  path: '',
  component: MainComponent,
  canActivate: [AuthGuestGuard],
  resolve: {
     initialData: InitialDataResolver,
  },
  children: [

    { path: 'cart', loadChildren: () => import('./pages/cart/cart.module').then(m => m.CartModule), data: { breadcrumb: 'Cart' } },
    { path: 'checkout', loadChildren: () => import('./pages/checkout/checkout.module').then(m => m.CheckoutModule), data: { breadcrumb: 'Checkout' } },
    { path: '', loadChildren: () => import('./pages/order/order.module').then(m => m.OrderModule) },
  ]
},
{ path: 'not-found', loadChildren: () => import('./pages/error404/error404.module').then(m => m.Error404Module) },
  { path: 'error/500', loadChildren: () => import('./pages/error500/error500.module').then(m => m.Error500Module) },

  { path: 'ecom-gateway', loadChildren: () => import('./pages/image/image.module').then(m => m.ImageModule) },

  { path: 'pincode', loadChildren: () => import('./theme/pincode/pincode.module').then(m => m.PincodeModule) },

  { path: 'paymentSucces', loadChildren: () => import('./payment-success/payment-success.module').then(m => m.PaymentSuccessModule) },

  { path: 'supplierRegistration', loadChildren: () => import('./pages/supplier-registration/supplier-registration.module').then(m => m.SupplierRegistrationModule) },

  { path: 'blogSection', loadChildren: () => import('./pages/home/blog-section/blog-section.module').then(m => m.BlogSectionModule) },

  { path: 'locationNotFound', loadChildren: () => import('./pages/location-not-found/location-not-found.module').then(m => m.LocationNotFoundModule) },

  { path: 'paymentSuccess', loadChildren: () => import('./pages/payment-completed/payment-completed.module').then(m => m.PaymentCompletedModule) },

  { path: 'slot', loadChildren: () => import('./pages/slot/slot.module').then(m => m.SlotModule) },
  { path: 'table', loadChildren: () => import('./pages/product/table/table.module').then(m => m.TableModule) },

  { path: 'deliveryLogin', loadChildren: () => import('./pages/delivery-login/delivery-login.module').then(m => m.DeliveryLoginModule) },

  { path: 'textEditor', loadChildren: () => import('./shared/text-editor/text-editor.module').then(m => m.TextEditorModule) },

  { path: 'phoneNumber', loadChildren: () => import('./pages/phone-number/phone-number.module').then(m => m.PhoneNumberModule) },

  { path: 'customOption', loadChildren: () => import('./pages/custom-option/custom-option.module').then(m => m.CustomOptionModule) },

  { path: 'youtubeVideo', loadChildren: () => import('./pages/home/youtube-video/youtube-video.module').then(m => m.YoutubeVideoModule) },

  { path: 'searchLocation', loadChildren: () => import('./search-location/search-location.module').then(m => m.SearchLocationModule) },

  { path: 'commonOrder', loadChildren: () => import('./shared/common-order/common-order.module').then(m => m.CommonOrderModule) },





  { path: '**', loadChildren: () => import('./pages/error404/error404.module').then(m => m.Error404Module)  },

 

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: false,
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
